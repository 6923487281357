<script setup>
import { onMounted, onUnmounted } from "vue";

import dlgModal from "@/common/components/dlg/dlgModal.vue";
import menuItem from "../../components/menu/menuItem.vue";

import useKeyboard from "@/common/modules/keyboard/compKeyboard.ts";

const emit = defineEmits(["keypressed"]);
const keyboard = useKeyboard();

function keyPress(key) {
  emit("keypressed", key);
}

let unsubscribe;
onMounted(() => {
  unsubscribe = keyboard.$onAction((action) => {
    if (action.name == "keydown") {
      const key = action.args[0];
      if ("*1*2*3*4*5*6*7*Enter*Backspace*".indexOf(`*${key}*`) >= 0) {
        emit("keypressed", key);
      }
    }
  });
});

onUnmounted(() => {
  unsubscribe();
});
</script>

<template>
  <dlg-modal 
    :visible="true"
  >
    <template v-slot:menu-left>
      <menu-item
        keyCodes="VKEY1,F1"
        class="side-menu-item key1"
        @unpress="keyPress('1')"
      />
      <menu-item
        keyCodes="VKEY2,F2"
        class="side-menu-item key2"
        @unpress="keyPress('2')"
      />
      <menu-item
        keyCodes="VKEY3,F3"
        class="side-menu-item key3"
        @unpress="keyPress('3')"
      />
      <menu-item
        keyCodes="VKEY4,F4"
        class="side-menu-item key4"
        @unpress="keyPress('4')"
      />
    </template>

    <template v-slot:menu-right>
      <menu-item
        keyCodes="VKEY5,F5"
        class="side-menu-item key5"
        @unpress="keyPress('5')"
      />
      <menu-item
        keyCodes="VKEY6,F6"
        class="side-menu-item key6"
        @unpress="keyPress('6')"
      />
      <menu-item
        keyCodes="VKEY7,F7"
        class="side-menu-item key7"
        @unpress="keyPress('7')"
      />
      <menu-item
        keyCodes="VKEY8,F8"
        class="side-menu-item ok"
        @unpress="keyPress('Enter')"
      />
    </template>

    <template v-slot:default>
      <slot />
    </template>
  </dlg-modal>
</template>

<style>
.side-menu-item.key1 {
  background-image: url("../../assets/img/svg/key1.svg");
}

.side-menu-item.key2 {
  background-image: url("../../assets/img/svg/key2.svg");
}

.side-menu-item.key3 {
  background-image: url("../../assets/img/svg/key3.svg");
}

.side-menu-item.key4 {
  background-image: url("../../assets/img/svg/key4.svg");
}

.side-menu-item.key5 {
  background-image: url("../../assets/img/svg/key5.svg");
}

.side-menu-item.key6 {
  background-image: url("../../assets/img/svg/key6.svg");
}

.side-menu-item.key7 {
  background-image: url("../../assets/img/svg/key7.svg");
}

.side-menu-item.ok {
  background-image: url("../../assets/img/svg/ok.svg");
}
</style>
