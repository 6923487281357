import { reactive } from "vue";

import Http from "@/common/utils/Http.js";
import HttpError from "@/common/utils/HttpError.js";

const entries = reactive({
  exceptions: [],
});

async function load(url) {
  try {
    const cfg = await Http.get(url);
    Object.assign(entries, cfg);
  } catch (err) {
    Object.assign(entries, {
      exceptions: [{
        url: err.url || url,
        code: err.code,
        message: err.message,
        stack: err.stack,
      }]
    });
  }
  return entries;
}

export default function () {
  return {
    entries,
    load,
  };
}
