import Http from '@/common/utils/Http.js';


export const nonce = {
	transport: Http.get,
	url: 'auth/nonce'
};

export const login = {
	transport: Http.post,
	url: 'auth/login'
};

export const logout = {
	transport: Http.post,
	url: 'auth/logout'
};

