<script setup>
import { ref, computed, watchEffect } from "vue";
import { storeToRefs } from "pinia";
import router from "@/common/utils/router.js";

import frmLogin from "./frmLogin.vue";
import frmNoToken from "./frmNoToken.vue";
import frmToken from "./frmToken.vue";

import { login } from "@/common/modules/credentials/auth.js";
import useCryptoStore from "@/common/modules/credentials/stCrypto.js";

const props = defineProps({
  action: { type: Function, default: (res) => router.go(-1) },
});

const crypto = useCryptoStore();
const { sn, cryptoStatus, hash, hashError, time } = storeToRefs(crypto);
const { version, cryptoInit, cryptoReset, cryptoSession } = crypto;
cryptoReset();

const pin = ref("");
const busy = ref(false);

const frm = computed(() => {
  let status = cryptoStatus.value;
  switch (status) {
    case "off":
      return frmLogin;
    case "no-token":
      return frmNoToken;
    case "no-password":
      return frmToken;
    case "pending":
      return frmToken;
    case "active":
      return frmLogin;
  }
});

function keyPressed(key) {
  if (!busy.value) {
    if (key == "Enter") {
      onEnter();
    } else if (key == "Backspace") {
      pin.value = pin.value.slice(0, -1);
    } else {
      pin.value += key;
    }
  }
}

async function onEnter() {
  busy.value = true;
  resolver = null;
  try {
    let res = null;
    if (frm.value == frmLogin) {
      res = await login(pin.value);
    } else if (frm.value == frmToken) {
      const obj = await cryptoInit(pin.value);
      res = await waitForSession(obj);
    }
    if (res && res.session_id) {
      cryptoStatus.value = null;
      props.action(res);
    }
  } catch (x) {
    console.log(x);
  } finally {
    pin.value = "";
    busy.value = false;
  }
}

let resolver = null;

watchEffect( () => {
  if(cryptoStatus.value != 'pending'){
    if(resolver){
       resolver(cryptoStatus.value);
       resolver = null;
    }
  }
})

async function waitForSession(obj){
  cryptoStatus.value = 'pending';
  const pr = new Promise( (resolve, reject) => {
    resolver = resolve;
  });
  startAnimation();
  const status = await pr;
  stopAnimation();
  if( status != 'active'){
    return null;
  }
  const res = await cryptoSession(obj);
  return res;
}

function startAnimation(){
  console.log('startAnimation');
}

function stopAnimation(){
  console.log('stopAnimation');
}

</script>

<template>
  <div>
    <component
      v-if="cryptoStatus"
      :is="frm"
      :pin="pin"
      :busy="busy"
      :sn = "sn"
      :version = "version"
      :time = "time"
      :hash = "hash"
      :hashError = "hashError"
      @keypressed="keyPressed"
    />
  </div>
</template>
