//document.addEventListener('contextmenu', event => event.preventDefault());

import { createApp } from 'vue'

import di from '@/common/utils/di.js'
import useKeyboard from '@/common/modules/keyboard/compKeyboard.ts';
import useCredStore from "@/common/modules/credentials/store.js";
import { createPinia, PiniaVuePlugin } from 'pinia';
import useConfig from "@/apps/login/config.js";
import App from './App.vue'
import i18n from '@/common/utils/i18n.js'

const { entries: cfgEntries, load: cfgLoad } = useConfig();
di.config = cfgEntries;

const vue = createApp(App);
const pinia = createPinia()
vue.use(i18n).use(pinia);
di.vue = vue;

document.addEventListener('touchstart', function(e) {
	  e.preventDefault();
});

const { clear: kbClear } = useKeyboard();
const credStore = useCredStore();
di.auth = credStore;

//window.screen.orientation.lock('landscape');

( async () => {
	try {
		//await cfgLoad('sys/globals');
		//i18n.global.locale = cfgEntries.locale;
		i18n.global.locale = 'ua';
		vue.mount('#vue-app');
	}
	catch( err ){
		document.getElementById('vue-app').innerHTML = `<h1> ${err.message} </h1>`; 
	}

})();
