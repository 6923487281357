<script setup>
import { ref, onMounted } from "vue";

import loginMenu from "./loginMenu.vue";

const props = defineProps({
  sn: { type: String },
  version: { type: String },
  time: { type: String },
  pin: { type: String, default: "" },
  hash: { type: String },
  hashError: { type: Boolean, default: false },
  busy: { type: Boolean, default: false },
});

const emit = defineEmits(["update:autologin", "keypressed"]);

function keyDown(event) {
  event.preventDefault();
}

function keyPressed(key) {
  emit("keypressed", key);
}

const pinBox = ref(null);
onMounted(() => {
  pinBox.value.focus();
});
</script>

<template>
  <login-menu @keypressed="keyPressed">
    <div class="login-content" :class="{ busy }">
      <div class="sn" v-if="sn">
        <table>
        <tr><td>sn</td><td>:</td><td>{{sn}}</td></tr> 
        <tr><td>v</td><td>:</td><td>{{version}}</td></tr> 
        <tr><td>time</td><td>:</td><td>{{time}}</td></tr> 
        <tr v-if="hash"><td>hash</td><td>:</td><td  :class="{error: hashError}">{{hash}}</td></tr> 
        </table>
      </div>
      <div class="pin">PIN:</div>
      <input
        ref="pinBox"
        name="pin"
        type="password"
        :value="pin"
        @keydown="keyDown($event)"
        autocomplete="no"
        autofocus
      />
    </div>
  </login-menu>
</template>

<style>

.login-content .error {
  color: red
}

.login-content {
  height: 100%;
  display: grid;
  grid:
    "sn sn sn  sn" 3fr
    ". lb1 pin ." 3fr
    ". .   .   ." 3fr
    /1fr 1fr 1fr 1fr;

  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  touch-action: none;
}

.login-content .sn {
  place-self: start;
  grid-area: sn;
  padding: 0.5rem;
}

.login-content .sn td:nth-child(2) {
  padding-right: 0.5rem;
}

.login-content.busy {
  background-color: gray;
}

.login-content div.pin {
  grid-area: lb1;
  font-size: 15vmin;
  margin: 2vmin;
}

.login-content input {
  font-size: 15vmin;
  font-family: caption;
}

.login-content input[name="pin"] {
  grid-area: pin;
  padding-left: 2vmin;
  width: 60vmin;
}
</style>
