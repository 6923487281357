import md5 from '@/common/utils/md5.js';
import useCredStore from "./store.js"

import { 
	nonce as apiNonce, 
	login as apiLogin,  
	logout as apiLogout,
} from './apiAuth.js';


export async function login( pin ){
    const { transport, url } = apiNonce;
    const res =  await transport( url );
    
    const cnonce = md5(Math.random().toString(36)+res.nonce);
    const params = {
        time: res.time,
        cnonce: cnonce,
        reply: md5(`${res.nonce}:${pin}:${cnonce}` ),
    };
    const {transport: transport1, url: url1} = apiLogin;
    const res1 = await transport1( url1, params );
    const credStore = useCredStore();
    credStore.setCredentials(res1);
    return res1;
};

export function logout(){ 
    const { transport, url } = apiLogout;
    transport( url );
    const credStore = useCredStore();
    credStore.$reset();
};



