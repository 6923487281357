<script setup>
import { ref, onMounted, computed } from "vue";
import vkbdItem from "@/common/components/vkbd/vkbdItem.vue";

/*
const props = withDefaults(defineProps({ action: Function }), {
  action: () => router.go(-1),
});
*/
const props = defineProps({
  sn: { type: String },
  version: { type: String },
  time: { type: String },
  pin: { type: String, default: "" },
  hash: { type: String },
  hashError: { type: Boolean, default: false },
  busy: { type: Boolean, default: false },
});

const maskedPin = computed(() => Array(props.pin.length).fill("*").join(""));
const emit = defineEmits(["keypressed"]);

function getButtons() {
  const ret = [];

  while (ret.length < 10) {
    const rnd = Math.round(Math.random() * 9.98 - 0.49).toFixed();
    if (!ret.includes(rnd)) ret.push(rnd);
  }
  return ret;
}

const vkbd = ref(getButtons());

function keyPressed(key) {
  if (key == "Enter") {
    vkbd.value = getButtons();
  }
  emit("keypressed", key);
}

function keyDown(event) {
  event.preventDefault();
}

const pinBox = ref(null);
onMounted(() => {
  pinBox.value.blur();
});
</script>

<template>
  <div class="token-content noselect" :class="{ busy }">
    <div class="sn" v-if="sn">
      <table>
       <tr><td>sn</td><td>:</td><td>{{sn}}</td></tr> 
       <tr><td>v</td><td>:</td><td>{{version}}</td></tr> 
       <tr><td>time</td><td>:</td><td>{{time}}</td></tr> 
       <tr v-if="hash"><td>hash</td><td>:</td><td  :class="{error: hashError}">{{hash}}</td></tr> 
      </table>
    </div>
    <div class="img" />
    <div class="pin" v-t="'login.enter_code'" />
    <input
      ref="pinBox"
      name="pin"
      :value="maskedPin"
      @keydown="keyDown($event)"
      autocomplete="no"
    />
    <div class="vkbd">
      <vkbd-item
        v-for="(item, idx) in vkbd"
        :keyChar="item"
        @press="keyPressed(item)"
        :key="idx"
        class="vkbd-button"
      >
        {{ item }}
      </vkbd-item>
      <vkbd-item
        class="vkbd-button backspace"
        @press="keyPressed('Backspace')"
        keyChar="Backspace"
      >
      </vkbd-item>
      <vkbd-item class="vkbd-button vkbd-ok" @press="keyPressed('Enter')" keyChar="Enter">
      </vkbd-item>
    </div>
  </div>
</template>

<style>

.token-content .error {
  color: red
}


.token-content {
  height: 100%;
  display: grid;

  grid:
    "sn   sn   sn" 1fr
    ".    img  . " 2fr
    ".    lb   . " 2fr
    ".    inp  . " 1fr
    "vkbd vkbd vkbd" 2fr;

  align-items: center;
  justify-items: center;
  touch-action: none;

  background-color: #111;
  color: #abb;
}


.token-content div.sn {
  grid-area: sn;
  place-self: start;
  color: #1DD420;
  font-size: 1.25rem;
  padding: 1vmin;
}

.token-content .sn td:nth-child(2) {
  padding-right: 0.5rem;
}

.token-content.busy div.img {
  background-image: url("../../assets/img/svg/key-green.svg");
  filter: hue-rotate(125deg) saturate(1000%);
	animation: rotateAnimation 1s linear 0.6s infinite;
}

@keyframes rotateAnimation {
	from {transform: rotateY(0deg);}
	to {transform: rotateY(360deg);}
}

.token-content div.pin {
  grid-area: lb;
  font-size: 10vmin;
}

.token-content div.vkbd {
  grid-area: vkbd;
  align-self: center;

  display: grid;
  grid-auto-flow: column;
  grid-gap: 3vmin;

  font-size: 7vmin;
}

.token-content div.vkbd-button {
  background-color: #abb;
  color: #111;
  border-radius: 1.5vmin;
  width: 8.5vmin;
  height: 8.5vmin;
  text-align: center;
}

.token-content div.backspace {
  filter: grayscale(1) contrast(200%) brightness(68%) sepia(25%) hue-rotate(130deg);
  background-color: white;
  color: hsl(180, 11%, 70%);
}

.backspace {
  background-image: url("../../assets/img/svg/backspace.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
}

.token-content div.vkbd-ok {
  background-color: magenta;
  filter: invert(100%);
}

div.vkbd-ok {
  background-image: url("../../assets/img/svg/enter1.svg");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: blue;
}

.token-content div.vkbd-button.pressed {
  background-color: gray;
}

.token-content div.vkbd-button.longpressed {
  background-color: black;
  color: gray;
}

.token-content div.vkbd-gap {
  width: 4vmin;
}

.token-content input[name="pin"] {
  grid-area: inp;
  font-size: 7vmin;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  width: 50vmin;
  height: 9vmin;
  padding: 3.5vmin 2.5vmin 1vmin 4vmin;
  line-height: 0.5vmin;
  background-color: white;
  border-radius: 1.5vmin;
  letter-spacing: 2.5vmin;
}

.token-content div.img {
  grid-area: img;
  background-image: url("../../assets/img/svg/key-green.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 100%;
}
</style>
