<script setup>
import { watch } from "vue";
import di from "@/common/utils/di.js";
import { deleteAllCookies, go, goLogin } from "@/common/utils/urlUtils.js";
import dlgLogin from "./dlgLogin.vue";
import Http from "@/common/utils/Http.js";
import useKeyboard from "@/common/modules/keyboard/compKeyboard.ts";

function reboot() {
  window.localStorage.clear();
  deleteAllCookies();
  document.cookie = "eraseCache=" + Date.now();
  goLogin();
}

let rebootTimer = null;

async function reset(dbReset) {
  if (dbReset) {
    if (rebootTimer) {
      clearTimeout(rebootTimer);
    }
    await Http.post("adm/erase");
    reboot();
  }
  if (!rebootTimer) {
    rebootTimer = setTimeout(() => {
      reboot();
    }, 800);
  }
}

const kb = useKeyboard();

watch(kb.keys, (v) => {
  if (v.get("VKEY1") && v.get("VKEY4") && v.get("VKEY8")) {
    reset(v.get("VKEY5"));
  }

});
</script>

<template>
  <dlg-login/>
  <div class="cfg-exceptions" v-if="di.config.exceptions.length > 0">
    Config Errors:
    <pre v-for="(err, err_idx) in di.config.exceptions">
      {{ err }}
    </pre>
  </div>
</template>

<style lang="scss">
@import "./assets/styles/app.scss";

#vue-app {
  height: 100%;
}

#app {
  background-color: lightgray;

	top: 0px;
 	/*padding-top: 1px;*/

	left: 0px;
  width: 100%;
	height: 100%;  
}


.cfg-exceptions {
  padding-top: 2vh;
  font-size: initial;
  font-weight: bold;
  color: black;
  background: white;
  width: 100%;
  overflow-x: auto;
}

</style>
